<template>
  <div>
    <v-card class="elevation-0">
      <Overlay :overlay="overlay" />
      <ChangeAlarmStatusDialog
        :dialogChangeAlarmStatus="dialogChangeAlarmStatus"
        @clicked="dialogChangeAlarmStatusEmit"
        :StoreObj="StoreObj"
      />
      <AlarmInformationDialog
        :StoreObj="StoreObj"
        :dialogAlarmInformation="dialogAlarmInformation"
        @clicked="dialogAlarmInformationEmit"
      />
      <CommentsDialog :StoreObj="StoreObj" :dialogComment="dialogComment" @clicked="dialogCommentEmit" />
      <CreateManualJob :dialogCreateManualJob="dialogCreateManualJob" @clicked="dialogCreateManualJobEmit" />
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="heading1 PrimaryFontColor">My Alarms</div>
            <v-btn
              icon
              color="primary"
              @click="
                GetAllWorkFlowsListMethod(
                  $store.getters.get_asset_object,
                  $store.getters.get_current_user_details.user_id,
                  job_status
                )
              "
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-select
              dense
              outlined
              class="field_height field_label_size FontSize maxWidth pt-4"
              :items="job_status_items"
              label="Alarm Status"
              item-text="text"
              item-value="value"
              v-model="job_status"
            ></v-select>
            <v-btn small color="primary" class="mt-n3 ml-2" @click="dialogCreateManualJob = true">
              <v-icon>mdi-plus</v-icon>
              Create Job
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-data-table dense :headers="alarmsHeaders" :items="GetAllWorkFlowsList">
          <template v-slot:[`item.sl_no`]="{ item }">
            <div class="fontSize">{{ GetAllWorkFlowsList.indexOf(item) + 1 }}</div>
          </template>
          <template v-slot:[`item.job_number`]="{ item }">
            <div class="fontSize">
              {{ item.job_number ? (item.job_number != "NOT_ASSIGNED" ? item.job_number : "-") : "-" }}
            </div>
          </template>
          <template v-slot:[`item.alarm_message`]="{ item }">
            <div class="fontSize">{{ item.alarm_message }}</div>
          </template>
          <template v-slot:[`item.tag_id`]="{ item }">
            <div class="fontSize">{{ item.tag_id }}</div>
          </template>
          <template v-slot:[`item.job_initiated_on`]="{ item }">
            <div class="fontSize">{{ new Date(item.job_initiated_on).toLocaleString("en-In") }}</div>
          </template>
          <template v-slot:[`item.job_status`]="{ item }">
            <div
              class="fontSize"
              :class="
                item.job_status == 'PENDING'
                  ? 'red--text'
                  : item.job_status == 'ACKNOWLEDGED'
                  ? 'blue--text'
                  : 'green--text'
              "
            >
              {{ item.job_status == "PENDING" ? "OPEN" : item.job_status
              }}<span class="black--text font-weight-bold" v-if="item.is_escalated == 'ESCALATED'">
                (Escalated To
                {{
                  $store.getters.get_user_email.trim() == item.escalated_to_user_email_id.trim()
                    ? "Me"
                    : item.escalated_to_user_email_id
                }})
              </span>
            </div>
          </template>
          <template v-slot:[`item.Action`]="{ item }">
            <v-btn color="green" icon @click="checkItem(item, 'INFORMATION')">
              <v-icon>mdi-information</v-icon>
            </v-btn>
            <v-btn color="green" v-if="item.has_access == true" icon @click="checkItem(item, 'ACTION')">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn color="green" icon @click="checkItem(item, 'COMMENT')">
              <v-icon>mdi-comment-text-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { GetAllWorkFlows } from "@/mixins/GetAllWorkFlows.js";
import Overlay from "@/components/Extras/Overlay.vue";
import CommentsDialog from "@/components/MyAlarms/Dialogs/CommentsDialog.vue";
import CreateManualJob from "@/components/MyAlarms/Dialogs/CreateManualJob.vue";
import AlarmInformationDialog from "@/components/MyAlarms/Dialogs/AlarmInformationDialog.vue";
import ChangeAlarmStatusDialog from "@/components/AlarmLogs/Dialogs/ChangeAlarmStatusDialog.vue";
export default {
  components: {
    Overlay,
    CommentsDialog,
    CreateManualJob,
    ChangeAlarmStatusDialog,
    AlarmInformationDialog,
  },
  mixins: [GetAllWorkFlows],
  data: () => ({
    StoreObj: {},
    overlay: false,
    dialogComment: false,
    dialogCreateManualJob: false,
    dialogAlarmInformation: false,
    dialogChangeAlarmStatus: false,
    alarmsHeaders: [
      { text: "Sl No", value: "sl_no" },
      { text: "Job No.", value: "job_number" },
      { text: "Name", value: "alarm_message" },
      { text: "Initiated On", value: "job_initiated_on" },
      { text: "Status", value: "job_status" },
      { text: "Action", value: "Action" },
    ],
    job_status: "PENDING",
    GetAllWorkFlowsList: [],
    job_status_items: [
      { text: "OPEN", value: "PENDING" },
      { text: "ACKNOWLEDGED", value: "ACKNOWLEDGED" },
      { text: "CLOSED", value: "CLOSED" },
    ],
  }),
  watch: {
    job_status(val) {
      if (val) {
        this.GetAllWorkFlowsListMethod(
          this.$store.getters.get_asset_object,
          this.$store.getters.get_current_user_details.user_id,
          this.job_status
        );
      }
    },
  },
  mounted() {
    this.GetAllWorkFlowsListMethod(
      this.$store.getters.get_asset_object,
      this.$store.getters.get_current_user_details.user_id,
      this.job_status
    );
  },
  methods: {
    dialogCreateManualJobEmit(Toggle) {
      this.dialogCreateManualJob = false;
      if (Toggle == 2) {
        this.GetAllWorkFlowsListMethod(
          this.$store.getters.get_asset_object,
          this.$store.getters.get_current_user_details.user_id,
          this.job_status
        );
      }
    },
    dialogCommentEmit() {
      this.dialogComment = false;
    },
    dialogAlarmInformationEmit() {
      this.dialogAlarmInformation = false;
    },
    dialogChangeAlarmStatusEmit(Toggle) {
      this.dialogChangeAlarmStatus = false;
      if (Toggle == 2) {
        this.GetAllWorkFlowsListMethod(
          this.$store.getters.get_asset_object,
          this.$store.getters.get_current_user_details.user_id,
          this.job_status
        );
      }
    },
    checkItem(item, action) {
      this.StoreObj = item;
      switch (action) {
        case "ACTION":
          this.dialogChangeAlarmStatus = true;
          break;
        case "INFORMATION":
          this.dialogAlarmInformation = true;
          break;
        case "COMMENT":
          this.dialogComment = true;
          break;
      }
    },
  },
};
</script>
