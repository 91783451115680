<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogAlarmInformation" persistent max-width="600px">
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="primary">
          <div><span v-text="'Alarm Details'"></span></div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogAlarmInformationEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-5 pb-0">
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" xs="12" class="font-weight-bold">Job Number </v-col>
            <v-col cols="12" sm="12" md="8" xs="12">
              :
              {{
                StoreObj.job_number ? (StoreObj.job_number != "NOT_ASSIGNED" ? StoreObj.job_number : "-") : "-"
              }}</v-col
            >
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" xs="12" class="font-weight-bold">Description </v-col>
            <v-col cols="12" sm="12" md="8" xs="12"> : {{ StoreObj.tag_description }}</v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" xs="12" class="font-weight-bold">Tag ID</v-col>
            <v-col cols="12" sm="12" md="8" xs="12">: {{ StoreObj.tag_id }}</v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" xs="12" class="font-weight-bold">Initiated On</v-col>
            <v-col cols="12" sm="12" md="8" xs="12"
              >: {{ new Date(StoreObj.job_initiated_on).toLocaleString("en-In") }}</v-col
            >
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" xs="12" class="font-weight-bold">Assigned To</v-col>
            <v-col cols="12" sm="12" md="8" xs="12"
              >:
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    v-text="
                      StoreObj.configured_users.length == 1
                        ? StoreObj.configured_users[0].user_email_id
                        : `${StoreObj.configured_users[0].user_email_id} + ${
                            StoreObj.configured_users.length - 1
                          } Others`
                    "
                  ></span>
                </template>
                <div v-for="(user, idx) in StoreObj.configured_users" :key="idx">
                  {{ user.user_email_id }}
                </div>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" xs="12" class="font-weight-bold">Status</v-col>
            <v-col cols="12" sm="12" md="8" xs="12">
              : {{ StoreObj.job_status }}
              <span
                class="black--text font-weight-bold"
                v-if="StoreObj.has_access == false && StoreObj.is_escalated == 'ESCALATED'"
              >
                (Escalated To {{ StoreObj.escalated_to_user_email_id }})
              </span></v-col
            >
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
            small
            class="borderRadius text-capitalize"
            color="primary"
            @click="dialogAlarmInformationEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { API } from "aws-amplify";
import { GetAllUsers } from "@/mixins/GetAllUsers.js";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogAlarmInformation: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  mixins: [GetAllUsers],
  data: () => ({
    Toggle: 0,
    loading: false,
    SnackBarComponent: {},
    action: "",
    comments: "",
    assign_to_user_id: "",
    actionStoreObjs: ["ACKNOWLEDGE", "REASSIGN"],
  }),
  watch: {
    dialogAlarmInformation(value) {
      if (value == true) {
        console.log("Check StoreObj", this.StoreObj);
        this.GetAllUsersListMethod();
      }
    },
  },
  methods: {
    validateMethod() {
      if (this.$refs.form.validate()) {
        this.takeActionOnAlarm();
      } else {
        self.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter Comments",
        };
      }
    },
    async takeActionOnAlarm() {
      this.loading = true;
      var self = this;
      var mutation_name = this.StoreObj.job_status != "ACKNOWLEDGED" ? "takeactiononworkflow" : "closeworkflow";
      const path = mutation_name;
      const myInit = {
        body: {
          organization_id: this.$store.getters.get_current_user_details.organization_id,
          user_id: this.$store.getters.get_current_user_details.user_id,
          workflow_id: this.StoreObj.workflow_id,
          transaction_id: this.StoreObj.transaction_id,
          action: this.StoreObj.job_status != "ACKNOWLEDGED" ? this.action : undefined,
          assign_to_user_id:
            this.StoreObj.job_status != "ACKNOWLEDGED"
              ? this.action == "REASSIGN"
                ? this.assign_to_user_id
                : undefined
              : undefined,
          comment: this.comments,
        },
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            self.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: response.Status_Message,
            };
            self.dialogAlarmInformationEmit((self.Toggle = 2));
            self.loading = false;
          } else {
            self.loading = false;
            self.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: response.errorMessage,
            };
          }
        })
        .catch((error) => {
          console.warn(error);
          this.loading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: error.errors[0].message,
          };
        });
    },
    dialogAlarmInformationEmit(Toggle) {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
