var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Snackbar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.dialogChangeAlarmStatus),callback:function ($$v) {_vm.dialogChangeAlarmStatus=$$v},expression:"dialogChangeAlarmStatus"}},[_c('v-card',[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"dark":"","dense":"","color":"primary"}},[_c('div',[_c('span',{domProps:{"textContent":_vm._s(
              _vm.StoreObj.job_status == 'PENDING' && _vm.StoreObj.has_access == true && _vm.StoreObj.is_escalated == 'ESCALATED'
                ? 'Re -Assign'
                : _vm.StoreObj.job_status == 'PENDING'
                ? 'Acknowledge Alarm'
                : _vm.StoreObj.job_status == 'ACKNOWLEDGED'
                ? 'Close Alarm'
                : 'Re-Open Alarm'
            )}})]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialogChangeAlarmStatusEmit((_vm.Toggle = 1))}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"mt-3 pb-0"},[_c('div',{staticClass:"fontSize mb-3"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Tag Name ")]),_vm._v(": "),_c('span',[_vm._v(_vm._s(_vm.StoreObj.tag_description))])]),_c('v-form',{ref:"form"},[(_vm.StoreObj.job_status == 'PENDING' && _vm.StoreObj.has_access == true && _vm.StoreObj.is_escalated == 'ESCALATED')?_c('v-select',{staticClass:"field_height field_label_size FontSize",attrs:{"dense":"","outlined":"","label":"Select User","item-text":"user_name","item-value":"user_id","items":_vm.GetAllUsersList,"rules":[function (v) { return !!v || 'Required'; }]},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"x-small":""}},[_c('span',[_vm._v(_vm._s(item.user_name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.assigned_to_user_id.length - 1)+" others) ")]):_vm._e()]}}],null,false,916051352),model:{value:(_vm.assigned_to_user_id),callback:function ($$v) {_vm.assigned_to_user_id=$$v},expression:"assigned_to_user_id"}}):_vm._e(),(_vm.StoreObj.job_status)?_c('v-textarea',{staticClass:"field_height field_label_size fontSize",attrs:{"rules":[function (v) { return !!v || 'Required'; }],"label":"Enter Your Comments","dense":"","rows":"2","outlined":""},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"pb-4"},[_c('v-spacer'),_c('v-btn',{staticClass:"borderRadius text-capitalize",attrs:{"small":"","color":"primary","outlined":""},on:{"click":function($event){_vm.dialogChangeAlarmStatusEmit((_vm.Toggle = 1))}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-close")]),_vm._v("Cancel")],1),_c('v-btn',{staticClass:"borderRadius text-capitalize mr-2",attrs:{"small":"","color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.validateMethod()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-check")]),_vm._v("Confirm")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }