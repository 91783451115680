<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogChangeAlarmStatus" persistent max-width="400px">
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="primary">
          <div>
            <span
              v-text="
                StoreObj.job_status == 'PENDING' && StoreObj.has_access == true && StoreObj.is_escalated == 'ESCALATED'
                  ? 'Re -Assign'
                  : StoreObj.job_status == 'PENDING'
                  ? 'Acknowledge Alarm'
                  : StoreObj.job_status == 'ACKNOWLEDGED'
                  ? 'Close Alarm'
                  : 'Re-Open Alarm'
              "
            ></span>
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogChangeAlarmStatusEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-3 pb-0">
          <div class="fontSize mb-3">
            <span class="font-weight-bold">Tag Name </span>: <span>{{ StoreObj.tag_description }}</span>
          </div>
          <v-form ref="form">
            <v-select
              dense
              outlined
              v-if="StoreObj.job_status == 'PENDING' && StoreObj.has_access == true && StoreObj.is_escalated == 'ESCALATED'"
              label="Select User"
              class="field_height field_label_size FontSize"
              item-text="user_name"
              item-value="user_id"
              :items="GetAllUsersList"
              :rules="[(v) => !!v || 'Required']"
              v-model="assigned_to_user_id"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip x-small v-if="index === 0">
                  <span>{{ item.user_name }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption"> (+{{ assigned_to_user_id.length - 1 }} others) </span>
              </template>
            </v-select>
            <v-textarea
              :rules="[(v) => !!v || 'Required']"
              label="Enter Your Comments"
              v-if="StoreObj.job_status"
              dense
              rows="2"
              outlined
              class="field_height field_label_size fontSize"
              v-model="comment"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius text-capitalize" color="primary" outlined @click="dialogChangeAlarmStatusEmit((Toggle = 1))"><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn>
          <v-btn small class="borderRadius text-capitalize mr-2" color="primary" :loading="loading" @click="validateMethod()"><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { API } from "aws-amplify";
import { GetAllUsers } from "@/mixins/GetAllUsers.js";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogChangeAlarmStatus: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  mixins: [GetAllUsers],
  data: () => ({
    Toggle: 0,
    comment: "",
    assigned_to_user_id: "",
    loading: false,
    SnackBarComponent: {},
  }),
  watch: {
    dialogChangeAlarmStatus(value) {
      if (value == true) {
        this.GetAllUsersListMethod();
        console.log("Check The StoreObj",this.StoreObj)
      }
    },
  },
  methods: {
    validateMethod() {
      if (this.$refs.form.validate()) {
        this.takeActionOnAlarm();
      } else {
        self.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter Comments",
        };
      }
    },
    async takeActionOnAlarm() {
      this.loading = true;
      var self = this;
      const path = "removealarmconfiguration";
      const myInit = {
        body: {
          command: "takeActionOnWorkflow",
          organization_id: self.$store.getters.get_current_user_details.organization_id,
          user_id: self.$store.getters.get_current_user_details.user_id,
          job_id: self.StoreObj.job_id,
          action:
            this.StoreObj.job_status == "PENDING" && this.StoreObj.has_access == true && this.StoreObj.is_escalated == "ESCALATED"
              ? "REASSIGN"
              : self.StoreObj.job_status == "PENDING"
              ? "ACKNOWLEDGE"
              : self.StoreObj.job_status == "ACKNOWLEDGED"
              ? "CLOSE"
              : "REOPEN",
          comment: self.comment,
          assigned_to_user_id: this.StoreObj.job_status == "PENDING" && this.StoreObj.has_access == true && this.StoreObj.is_escalated == "ESCALATED" ? this.assigned_to_user_id : undefined,
        },
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            self.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: response.Status_Message,
            };
            self.dialogChangeAlarmStatusEmit((self.Toggle = 2));
            self.loading = false;
          } else {
            self.loading = false;
            self.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: response.errorMessage,
            };
          }
        })
        .catch((error) => {
          console.warn(error);
          this.loading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: error.errors[0].message,
          };
        });
    },
    dialogChangeAlarmStatusEmit(Toggle) {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
