<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent max-width="400px" v-model="dialogCreateManualJob">
      <v-card>
        <v-toolbar dark color="primary" dense class="elevation-0">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          <div>Configure Alarm</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogCreateManualJobEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pb-0">
          <v-form ref="form" class="mt-4">
            <v-textarea dense rows="2" outlined class="field_height field_label_size FontSize" label="Job Description" v-model="create_job.job_description"></v-textarea>
            <v-select
              dense
              outlined
              multiple
              label="User"
              class="field_height field_label_size FontSize"
              item-text="user_name"
              item-value="user_id"
              :items="GetAllUsersList"
              :rules="[(v) => !!v || 'Required']"
              v-model="create_job.configured_users"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip x-small v-if="index === 0">
                  <span>{{ item.user_name }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption"> (+{{ create_job.configured_users.length - 1 }} others) </span>
              </template>
            </v-select>
            <v-select
              dense
              outlined
              multiple
              label="Re-Openable Users"
              class="field_height field_label_size FontSize"
              item-text="user_name"
              item-value="user_id"
              :items="GetAllUsersList"
              :rules="[(v) => !!v || 'Required']"
              v-model="create_job.job_reopenable_users"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip x-small v-if="index === 0">
                  <span>{{ item.user_name }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption"> (+{{ create_job.job_reopenable_users.length - 1 }} others) </span>
              </template>
            </v-select>
            <v-checkbox dense class="ma-0 pa-0" v-model="create_job.should_have_job_number" label="Should Have Job Number ?"></v-checkbox>
            <v-checkbox dense class="ma-0 pa-0" v-model="create_job.is_analysis_required" label="Is Analysis Required ?"></v-checkbox>
            <v-checkbox dense class="ma-0 pa-0" v-model="create_job.is_escalation_enabled" label="Is Escalation Enabled ?"></v-checkbox>
            <v-select
              dense
              outlined
              v-if="create_job.is_escalation_enabled == true"
              label="Escalation Delay (In Minutes)"
              class="field_height field_label_size FontSize"
              :items="escalationDelayItems"
              :rules="[(v) => !!v || 'Required']"
              v-model="create_job.escalation_delay"
            >
            </v-select>
            <v-select
              dense
              outlined
              v-if="create_job.is_escalation_enabled == true"
              label="Escalated Users"
              class="field_height field_label_size FontSize"
              item-text="user_name"
              item-value="user_id"
              :items="GetAllUsersList"
              :rules="[(v) => !!v || 'Required']"
              v-model="create_job.escalated_to_user"
            >
            </v-select>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius" outlined color="primary" @click="dialogCreateManualJobEmit((Toggle = 1))"><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn>
          <v-btn small class="mr-2 borderRadius" color="primary" :loading="loading" @click="validateFormMethod()"><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { GetAllUsers } from "@/mixins/GetAllUsers.js";
import { API } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
import CountryList from "@/JsonFiles/CountryList.json";
export default {
  mixins: [GetAllUsers],
  props: {
    dialogCreateManualJob: Boolean,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    user_type: "",
    userTypeItems: [
      { text: "Admin", value: "ADMIN" },
      { text: "Normal User", value: "NORMAL_USER" },
    ],
    loading: false,
    create_job: {
      configured_users: [],
      job_reopenable_users: [],
      should_have_job_number: false,
      is_analysis_required: false,
      is_escalation_enabled: false,
      escalation_delay: 0,
      escalated_to_user: "",
    },
    escalationDelayItems: [],
    SnackBarComponent: {},
    CountryList: [],
  }),
  watch: {
    dialogCreateManualJob(value) {
      if (value == true) {
        this.GetAllUsersListMethod();
        // if (this.StoreObj.is_alarm_configured == true) {
        //   this.create_job.configured_users = this.StoreObj.configured_users.map((user) => user.user_id);
        //   this.create_job.job_reopenable_users = this.StoreObj.job_reopenable_users ? this.StoreObj.job_reopenable_users : [];
        //   this.create_job.should_have_job_number = this.StoreObj.should_have_job_number ? this.StoreObj.should_have_job_number : "";
        //   this.create_job.is_analysis_required = this.StoreObj.is_analysis_required ? this.StoreObj.is_analysis_required : "";
        //   this.create_job.is_escalation_enabled = this.StoreObj.is_escalation_enabled ? this.StoreObj.is_escalation_enabled : "";
        //   this.create_job.escalation_delay = this.StoreObj.escalation_delay ? this.StoreObj.escalation_delay : "";
        //   this.create_job.escalated_to_user = this.StoreObj.escalated_to_user ? this.StoreObj.escalated_to_user : "";
        // }
      }
    },
  },
  mounted() {
    this.CountryList = CountryList;
    this.escalationDelayItems = [];
    let i = 0;
    while (i < 60) {
      i++;
      this.escalationDelayItems.push(i);
    }
  },
  methods: {
    validateFormMethod() {
      if (this.$refs.form.validate()) {
        this.allCommonMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Inputs",
        };
      }
    },
    async allCommonMethod() {
      var mutationname = "removealarmconfiguration";
      var inputParams = {
        command: "initiateManualJob",
        organization_id: this.$store.getters.get_current_user_details.organization_id,
        configured_users: this.create_job.configured_users,
        should_have_job_number: this.create_job.should_have_job_number ? this.create_job.should_have_job_number : false,
        asset_id: this.$store.getters.get_asset_object.asset_id,
        job_reopenable_users: this.create_job.job_reopenable_users,
        is_analysis_required: this.create_job.is_analysis_required ? this.create_job.is_analysis_required : false,
        job_description: this.create_job.job_description,
        is_escalation_enabled: this.create_job.is_escalation_enabled ? this.create_job.is_escalation_enabled : false,
        escalation_delay: this.create_job.is_escalation_enabled == true ? this.create_job.escalation_delay : undefined,
        escalated_to_user: this.create_job.is_escalation_enabled == true ? this.create_job.escalated_to_user : undefined,
        updater_email_id: this.$store.getters.get_user_email,
      };

      var self = this;
      self.loading = true;
      const path = mutationname;
      const myInit = {
        body: inputParams,
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: response.Status_Message,
            };
            this.dialogCreateManualJobEmit((this.Toggle = 2));
            this.loading = false;
          } else {
            this.loading = false;
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: response.errorType,
            };
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    dialogCreateManualJobEmit(Toggle) {
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
