var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"elevation-0"},[_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('ChangeAlarmStatusDialog',{attrs:{"dialogChangeAlarmStatus":_vm.dialogChangeAlarmStatus,"StoreObj":_vm.StoreObj},on:{"clicked":_vm.dialogChangeAlarmStatusEmit}}),_c('AlarmInformationDialog',{attrs:{"StoreObj":_vm.StoreObj,"dialogAlarmInformation":_vm.dialogAlarmInformation},on:{"clicked":_vm.dialogAlarmInformationEmit}}),_c('CommentsDialog',{attrs:{"StoreObj":_vm.StoreObj,"dialogComment":_vm.dialogComment},on:{"clicked":_vm.dialogCommentEmit}}),_c('CreateManualJob',{attrs:{"dialogCreateManualJob":_vm.dialogCreateManualJob},on:{"clicked":_vm.dialogCreateManualJobEmit}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('div',{staticClass:"heading1 PrimaryFontColor"},[_vm._v("My Alarms")]),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.GetAllWorkFlowsListMethod(
                _vm.$store.getters.get_asset_object,
                _vm.$store.getters.get_current_user_details.user_id,
                _vm.job_status
              )}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('v-spacer'),_c('v-select',{staticClass:"field_height field_label_size FontSize maxWidth pt-4",attrs:{"dense":"","outlined":"","items":_vm.job_status_items,"label":"Alarm Status","item-text":"text","item-value":"value"},model:{value:(_vm.job_status),callback:function ($$v) {_vm.job_status=$$v},expression:"job_status"}}),_c('v-btn',{staticClass:"mt-n3 ml-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){_vm.dialogCreateManualJob = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Create Job ")],1)],1)],1)],1),_c('v-card-text',{staticClass:"py-0"},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.alarmsHeaders,"items":_vm.GetAllWorkFlowsList},scopedSlots:_vm._u([{key:"item.sl_no",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(_vm._s(_vm.GetAllWorkFlowsList.indexOf(item) + 1))])]}},{key:"item.job_number",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(" "+_vm._s(item.job_number ? (item.job_number != "NOT_ASSIGNED" ? item.job_number : "-") : "-")+" ")])]}},{key:"item.alarm_message",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(_vm._s(item.alarm_message))])]}},{key:"item.tag_id",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(_vm._s(item.tag_id))])]}},{key:"item.job_initiated_on",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(_vm._s(new Date(item.job_initiated_on).toLocaleString("en-In")))])]}},{key:"item.job_status",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"fontSize",class:item.job_status == 'PENDING'
                ? 'red--text'
                : item.job_status == 'ACKNOWLEDGED'
                ? 'blue--text'
                : 'green--text'},[_vm._v(" "+_vm._s(item.job_status == "PENDING" ? "OPEN" : item.job_status)),(item.is_escalated == 'ESCALATED')?_c('span',{staticClass:"black--text font-weight-bold"},[_vm._v(" (Escalated To "+_vm._s(_vm.$store.getters.get_user_email.trim() == item.escalated_to_user_email_id.trim() ? "Me" : item.escalated_to_user_email_id)+") ")]):_vm._e()])]}},{key:"item.Action",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"color":"green","icon":""},on:{"click":function($event){return _vm.checkItem(item, 'INFORMATION')}}},[_c('v-icon',[_vm._v("mdi-information")])],1),(item.has_access == true)?_c('v-btn',{attrs:{"color":"green","icon":""},on:{"click":function($event){return _vm.checkItem(item, 'ACTION')}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1):_vm._e(),_c('v-btn',{attrs:{"color":"green","icon":""},on:{"click":function($event){return _vm.checkItem(item, 'COMMENT')}}},[_c('v-icon',[_vm._v("mdi-comment-text-outline")])],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }